import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import josmo from '../../assets/images/projects/product/3-furniture-pieces/josmo-logo.png'

const ProductDesignFurniturePieces = props => (
  <Layout>
    <Helmet>
      <title>3 Furniture Pieces</title>
      <meta
        name="commercial and residential furniture design"
        content="Product Design"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.fpcover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">PRODUCT DESIGN</span>
            <h1>3 furniture pieces.</h1>
            <h3>
              First a classic armchair that is simple yet luxurious and will fit
              in well to any office space. Second, an ottoman inspired by the
              opulence of the roaring '20s, a true statement piece that speaks
              style and class. Lastly, a bespoke dressing table that has a
              perfect blend of antiquity and elegance feel thanks to its natural
              copper patina finish which is capped off with shiny aluminium rock
              handles.
            </h3>
          </header>
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * The following design were made during my time as a Junior
                  Designer at Josmo Studio, under the guidance of Anjali Mody.
                  For confidentiality & NDA reasons only a small section of the
                  work done is shown here.
                </p>
                <p>
                  <u>Get in touch</u> with me for more details related to this
                  project; such as my role, the problems i faced, the processes
                  i used and how i addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.josmo_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={josmo} alt="josmo" />
                </div>
                <p>
                  Josmo Studio is a Mumbai + Goa based collective that
                  transforms radical ideas into purposeful, beautiful products.
                  Josmo believes in essence, the magic lies within transforming
                  radical ideas into purposeful furniture pieces custom made to
                  fit together, but more importantly, to fit you.
                </p>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  To design 3 pieces of furniture for different usage contexts
                  and needs.
                </p>
                <div>
                  <ol>
                    <li>
                      A lounge chair for the main lobby of a Law firm so that
                      visitors could wait comfortably.
                    </li>
                    <li>
                      An ottoman for a residential space that would stand out as
                      a statement piece.
                    </li>
                    <li>A dressing table for an elegant residential space.</li>
                  </ol>
                </div>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2015
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 9 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Commercial & Residential
                  furniture design.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of Josmo Studio in
                  Mumbai, India
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Design research, conceptualisation,
                  sketching of final ideas & detailing.
                </span>
              </div>
            </div>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
            <p className="full-text">
              First step involved visiting the site in order to understand the
              physical space and the environment this furniture was to be placed
              in.
            </p>
            <p className="full-text">
              Benchmarking and design research on other designs was the carried
              out to know the latest trends in materials and finishes. Various
              ideas were sketched by experimenting with different forms, seating
              style and material combination. Few different concepts of the
              furniture were drawn up and modelled keeping in mind the overall
              theme and requirements of the 3 furniture pieces and how they
              would be used.
            </p>
            <p className="full-text">
              A concept was finalised for each of the 3 furniture pieces and
              they were further detailed out by designing the finer elements and
              choosing the appropriate materials.
            </p>
            <p className="full-text">
              Next, preparation for the production of the pieces was carried
              out. This involved making technical drawings and 3d modelling and
              rendering all the 3 furniture pieces in their environment they
              will be placed in. These documents were handed over to the
              carpenter/producer and supervision was done in order to ensure
              that the designs were translated from paper to the real thing.
            </p>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <h3>
                All 3 furniture pieces were produced with great quality, making
                them feel that they were part of the space.
              </h3>

              <p className="full-text">
                An elegant piece for a contemporary office, the Barrister Lounge
                Chair is hand-crafted in solid wood with artisanal brass
                detailing and smooth, durable upholstery. Wood with upholstery,
                natural oil finish with teak stained, brass detail.
              </p>
              <p className="full-text">
                The Gatsby Ottoman has a rich indigo hue that adds to the
                majesty of the piece while the hand-crafted brass accents
                enhance the vintage feel. The velvet used is of an extremely
                high quality and reflects the luxury and glamour of the 1920s.
              </p>
              <p className="full-text">
                The Rock Vanity table brings together perfectly the interplay of
                materials and textures. The shiny-smooth look contrasts
                excellently with the rough-grungy feel of the copper patina
                making it a stand-out piece in the dressing area of the house.
              </p>
            </div>
            <Img
              fluid={props.data.outcome.childImageSharp.fluid}
              alt="Furniture Pieces Outcome"
              title="Furniture Pieces Outcome"
            />
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/work"> my work</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/product-design-hop-philips">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hop.</div>
                <div className="text">
                  An interactive & playful street element to promote awareness
                  and values of the brand Philips Lighting
                </div>
                <div className="tags">
                  <ul>
                    <li>PRODUCT DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-kin">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">kin.</div>
                <div className="text">
                  Conversational-based service for Type II Diabetes management
                  that leverages on trusted relationships.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>UX STRATEGY</li>
                    <li>USER EXPERIENCE</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProductDesignFurniturePieces

export const fluidFurniturePiecesImage = graphql`
  fragment fluidFurniturePiecesImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    fpcover: file(
      relativePath: {
        eq: "projects/product/3-furniture-pieces/cover/furniture-pieces-cover image.jpg"
      }
    ) {
      ...fluidFurniturePiecesImage
    }
    outcome: file(
      relativePath: {
        eq: "projects/product/3-furniture-pieces/outcome/furniture-design-final outcome.jpg"
      }
    ) {
      ...fluidFurniturePiecesImage
    }
    project1: file(relativePath: { eq: "projects/8.png" }) {
      ...fluidFurniturePiecesImage
    }
    project2: file(relativePath: { eq: "projects/6.png" }) {
      ...fluidFurniturePiecesImage
    }
  }
`
